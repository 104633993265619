import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Marketing Associate</h1>
      <div className="jobdescription col-md-12">
        An incredible product is not worth much if we can’t get people excited
        about it. Be a part of the team that’s building Uno be one of the best
        brands in the world.{" "}
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>Help build the Uno brand from the ground up.</li>
            <li>
              Utilize traditional above the line, social media and digital
              marketing channels to promote Uno products based on launch and
              growth roadmap.
            </li>
            <li>
              Develop customer-focused and data-driven experiments to increase
              user acquisition and usage.
            </li>
            <li>
              Collaborate with product and sales teams to understand market and
              business needs.
            </li>
            <li>
              Keep track of analytics and monitoring KPIs reports to improve
              effectiveness and propose new experiments.
            </li>
            <li>
              Create compelling marketing to reach targeted consumer audiences.
            </li>
            <li>
              Prepare decks and other communication collateral for leadership.
            </li>
            <li>
              Organize promotional events and coordinate company attendance at
              shows and conferences.
            </li>
            <li>
              Hire and manage freelance professionals to execute marketing
              initiatives.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>Creative and tasteful in everything you touch.</li>
            <li>Well spoken and an articulate writer.</li>
            <li>Curious and eager to learn new tools and methods.</li>
            <li>Always proactively seeking new opportunities.</li>
            <li>
              Able to gain deep knowledge and adapt jargon in unfamiliar areas.
            </li>
            <li>Goal-oriented and able to see the big picture.</li>
            <li>
              Equally analytical and intuitive in your approach to
              problem-solving.
            </li>
            <li>
              Energized by a fast-paced environment and juggling multiple
              projects.
            </li>
            <li>
              Someone who loves to take responsibility and make decisions.
            </li>
            <li>Passionate about sustainability. </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>BA in Marketing, Communications, or relevant field</li>
            <li>
              3+ proven work experience as a Marketing Associate, Marketing
              Assistant or similar role.
            </li>
            <li>
              Experience marketing consumer products, telcom or tech in
              direct-to-consumer framework.
            </li>
            <li>Knowledge of digital marketing tools and techniques.</li>
            <li>
              Strong copywriting skills to effectively craft messaging to
              different types of audience.
            </li>
            <li>
              Prep creative briefs collaborating cross-functionally to gather
              necessary information
            </li>
            <li>
              Understanding of brand hierarchy, messaging and methodologies to
              articulate them to different stakeholders.
            </li>
            <li>
              Prior experience leveraging owned channels such as CRM, in-app,
              push messaging, website, etc.
            </li>
            <li>Extensive experience with G-Suite, Office 365 and Keynote.</li>
            <li>
              Experience working with external advertising/media agencies, PR{" "}
            </li>
            <li>
              Experience working at a marketing or advertising agency is a plus.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
